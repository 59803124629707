<template>
  <div class="popup-modal" role="dialog" v-if="isVisible">
    <div class="wrapper cancel-lesson">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <div></div>
            <h5 class="modal-title text-center">{{ $t("mypage.cancel_lesson.title") }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              @click="_cancel"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <FormView @submit="handleCancel">
            <div class="modal-body text-left">
              <div :class="`alert alert-${msgType}`" role="alert" v-if="msg">
                  {{msg}}
              </div>
              <div class="content-1">
                <div class="card">
                    <div class="card-header">
                        <div class="info" v-if="item.lesson_level">{{ $t(`lesson.${item.lesson_level}`)}}</div>
                        <div class="card-title">{{ item.lesson_title }}</div>
                    </div>
                    <div class="card-body" id="table">
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.datetime')}}
                            </div>
                            <div class="td w-100 text-descr">
                              <template v-if="item.lesson_schedule_start_datetime">
                                {{ formatDateJa(item.lesson_schedule_start_datetime, "YYYY年MMMMDD日") }}  {{ _formatTime(item.lesson_schedule_start_datetime, item.lesson_schedule_end_datetime) }}
                              </template>
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.teacher')}}
                            </div>
                            <div class="td w-100 text-descr">
                                {{ item.teacher_name }}
                            </div>
                        </div>
                        <div class="tr">
                            <div class="td text-title">
                                {{ $t('lesson.student')}}
                            </div>
                            <div class="td w-100 text-descr">
                                {{ item.student_name }}
                            </div>
                        </div>
                    </div>
                </div>
              </div>
              <div class="content-2">
                  <div class="text-center">
                    <b class="q">相談をキャンセルして本当によろしいですか？</b>
                  </div>
                  <div class="card">
                        <b class="text-pre-line ml-1 mt-3">【ご注意事項】</b>
                        <div class="text-pre-line ml-1">＜予約のキャンセル＞</div>
                        <ul class="ul">
                            <li> 予約相談開始前24時間未満の場合、生徒はキャンセルをすることができません。 </li>
                            <li> 購入後5日が経過している場合、講師・生徒ともにキャンセルをすることができません。</li>
                        </ul>
                  </div>
              </div>
            </div>
            <div class="modal-footer d-flex justify-content-center">
               <button type="button" @click="_cancel" class="btn btn-primary" :disabled="cancelling">
                  {{ $t("mypage.cancel_lesson.return") }}
              </button>
              <button type="submit" class="btn " :disabled="cancelling">
                  {{ $t("mypage.cancel_lesson.button") }} {{cancelling?'...':''}}
              </button>
            </div>
          </FormView>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    isVisible: {},
    transactionId: {},
    item: {}
  },
  name: "CancelLesson",
  data() {
    return {
      cancelling: false,
      msgType: "danger",
      msg: "",
    };
  },
  methods: {
    ...mapActions({
          cancelCaptureSale: "mypage/cancelCaptureSale",
      }),
    _cancel(status = false) {
      this.$emit("cancel", status);
    },
    async handleCancel() {
      if(this.cancelling)return;
      this.cancelling = true;
      this.msg = "";
      let cancelInfo = {
          reason: "NA",
          transaction_id: this.transactionId
      }
      await this.cancelCaptureSale({
          data: cancelInfo,
          cb: (response) => {
            let { data, meta } = response;
            if(data.status == true){
              this.msg = "";
              this._cancel(true);
            }else{
               this.msgType = "danger";
               if(data.message){
                 this.msg = data.message;
               }else{
                 this.msg = meta.message;
               }
            }
          }
      });
      this.cancelling = false;
    },
    _formatTime(start, end) {
        return (
            this.dateInstance(start).format("HH:mm") +
            " - " +
            this.dateInstance(end).format("HH:mm")
        );
    },
  },
};
</script>
<style scoped>
  .ul{
    padding-inline-start: 25px;
  }
</style>