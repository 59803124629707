<template>
    <div class="list">
      <template v-if="list.data.length > 0">
        <div class="item" v-for="(item, key) in list.data" :key="key">
          <div class="card">
            <div class="card-header">
              <div class="info" v-if="item.lesson_level">{{ $t(`lesson.${item.lesson_level}`)}}</div>
              <div class="card-title">{{ $t('lesson.title')}}: {{ item.lesson_title }}</div>
            </div>
            <div class="card-body" id="table">
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.datetime')}}
                    </div>
                    <div class="td w-100 text-descr">
                      <template v-if="item.lesson_schedule_start_datetime">
                        {{ formatDateJa(item.lesson_schedule_start_datetime, "YYYY年MMMMDD日") }}  {{ _formatTime(item.lesson_schedule_start_datetime, item.lesson_schedule_end_datetime) }}
                      </template>
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.teacher')}}
                    </div>
                    <div class="td w-100 text-descr">
                      {{ item.teacher_name }}
                    </div>
                </div>
                <div class="tr">
                    <div class="td text-title">
                      {{ $t('lesson.student')}}
                    </div>
                    <div class="td w-100 text-descr">
                      {{ item.student_name }}
                    </div>
                </div>
            </div>
            <div class="text-center action-block">
                <router-link :to="item.talk_room_url" 
                    class="btn btn-primary">
                    {{ $t('lesson.go_to_talk_room')}}
                </router-link>
                <button @click="handleClick(item)" 
                  class="btn" 
                  v-if="item.comment_rated == null">
                  {{ $t('lesson.mark-complete')}}
                </button>
                <div class="reviewed-star rate" v-else>
                    <star-rating 
                      :rating="item.comment_rated" 
                      :star-size="20"
                      :show-rating="false"
                      :read-only="true"
                    />
                    <small>{{_totalRate(item.comment_rated)}}</small>
                  </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
          <EmptyItem />
      </template>
      <Pagination
        :length="list.meta.pagination.last"
        v-model="filters.page"
        @input="onPageChange"
        v-if="list.meta.pagination && list.meta.pagination.last > 1"
      />
      <RatePopup
        :isVisible="this.isVisible"
        :data="lessonData"
        @cancel="actionCancel"
        style="z-index: 9999"
      />
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import RatePopup from "./components/rate-popup.vue"
import StarRating from 'vue-star-rating'
import EmptyItem from './components/empty.vue';

export default {
    name: "finished",
    components: {
      RatePopup,
      StarRating,
      EmptyItem
    },
    data() {
        return {
            filters: {
                page: 1,
                tab: "finished",
            },
            request: {
              type: "finished",
              limit: 10,
              page: 1,
            },
            isVisible: false,
            lessonData: null
        };
    },
    async created() {
        this.filters = await this.$router.getQuery(this.filters);
        this.request = {
              ...this.request,
              page: this.filters.page,
          };
        await this.getLessons(this.request);
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                this.filters = this.$router.getQuery(this.filters);
                this.$router.setQuery(this.filters);
                this.request = {
                    ...this.request,
                    page: this.filters.page,
                };
                this.getLessons(this.request);
            },
        },
    },
    computed: {
        ...mapGetters({
            list: "mypage/getMyPageFinishedLesson"
        })
    },
    methods: {
        ...mapActions({
            getLessons: "mypage/fetchMypageLessonList"
        }),
        onPageChange() {
            this.$router.setQuery(this.filters);
        },
        handleClick(item) {
            this.lessonData = item;
            this.isVisible = true;
        },
        actionCancel() {
          this.isVisible = false;
        },
        _formatTime(start, end) {
            return (
                this.dateInstance(start).format("HH:mm") +
                " - " +
                this.dateInstance(end).format("HH:mm")
            );
        },
        _totalRate(rate) {
          if(!rate)return;
          return Number(rate).toFixed(1);
        },
    },
}
</script>